<template>
  <div data-cy="page-profile-edit">
    <h1
      class="header-text"
      ref="headerEditProfile"
      aria-labelledby="header for edit profile page"
      data-cy="header-profile-edit"
      autofocus
    >
      Edit Profile
    </h1>
    <div class="subhead-text text-2xl">
      Manage the information you share with the Mentor Externship Program. The
      more details you provide helps us build a better experience for you.
    </div>
    <add-edit-student
      v-if="!student.student_loading"
      :student="local_student"
      :acad_level="getAcadLevel"
      @submit="putStudent"
      @cancel="$router.go(-1)"
    />
    <div v-else>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import { emitAlert } from "@/composables/alerts";
import AddEditStudent from "@/components/reusable-components/AddEditStudent";

export default {
  name: "ProfileEdit",
  components: {
    AddEditStudent,
    LoadingSpinner,
  },
  data() {
    return {
      saving: false,
      local_student: {},
      lookup_selection: [],
    };
  },
  created() {
    this.local_student = { ...this.student };
  },
  mounted() {
    this.$refs.headerEditProfile.focus();
  },
  methods: {
    putStudent(data) {
      this.putStudentResource(data)
        .then(() => {
          emitAlert("success", "Profile Updated", {
            response: "Your profile has been successfully updated!",
            timeout: 10000,
          });
        })
        .then(() => {
          if (location.pathname.includes("summer")) {
            //takes you to summer url otherwise its normal year and goes to normal time
            // this.$router.push("/summer", query);
            this.$router.push({
              path: "/summer",
              query: { preferences: "updated" },
            });
          } else {
            this.$router.push("/profile");
          }
        })
        .finally(() => (this.saving = false));
    },
    ...mapActions(["putStudentResource"]),
  },
  computed: {
    ...mapState({
      student: (state) => state.student,
    }),
    ...mapGetters([
      "getAcadLevel",
      "lookup_states",
      "lookup_pairing_factors",
      "lookup_practice_areas",
      "lookup_ethnicity",
      "lookup_pronouns_options",
      "lookup_gender_options",
      "lookup_prefix_options",
    ]),
  },
};
</script>
